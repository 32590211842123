@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");
/* font-family: 'Roboto', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");
/* font-family: 'Poppins', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Capriola&display=swap');
/* font-family: 'Capriola', sans-serif; */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}
a {
  text-decoration: none;
}

/*header*/
#nav {
  padding: 15px 90px;
}
/*header*/

/*Editor component*/

.container-editor {
  display: flex;
  flex-direction: column;
}

.output,
.editor {
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
}

textarea:focus {
  outline: none !important;
  border: 1px solid rgb(10 132 2555/1);
}
/*Editor component*/


@media screen and (max-width: 768px) {
  .split-container.vertical {
    display: block !important;
  }
}


/* * {
  box-sizing: border-box;
} */
.whiteboard {
  /* position: absolute; */
}
.color {
  height: 17px;
  width: 17px;
  /* border-radius: 12px; */
  margin: 2px 4px;
  padding: 10px;
  border: 1px solid rgb(165, 165, 165);
  cursor: pointer;
}

.color.black {
  background-color: black;
}
.color.red {
  background-color: #dc3545;
}
.color.green {
  background-color: #28a745;
}
.color.blue {
  background-color: #007bff;
}
.color.yellow {
  background-color: #ffc107;
}
.color.white {
  background-color: white;
}
.whiteboard {
  /* object-fit: contain; */
  border: 1px solid black;
  cursor: crosshair;
  /* position: fixed; */
  /* margin: 0;
  padding: 0; */
  width: 100%;
  height: 60vh;
}

.whiteboard.eraserCursor {
  cursor: url("../svg/Eraser-Cursor.png") 8 8, pointer;
  /* cursor: wait; */
}
.whiteboard.pencilCursor {
  /* cursor: url("../svg/Eraser-Cursor.png"), auto; */
  cursor: crosshair;
}
